import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react';
import LeftSideBar from './components/LeftSideBar';
import RightSideBar from './components/RightSideBar';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Resume from './components/Resume';
import Contact from './components/Contact';

import ProfileIMG from './media/img/zeeshan.png'
import ZeeshanCV from './media/cv/M.Zeeshan Ali Resume.pdf'

function App() {
  return (
    <BrowserRouter>
      <RoutingLayout />
    </BrowserRouter>
  );
}

function RoutingLayout() {
  const [handleContactDetail, sethandleContactDetail] = useState(window.innerWidth > 870);
  const location = useLocation();

  useEffect(() => {
    const deviceWidth = () => {
      sethandleContactDetail(window.innerWidth < 870);
    }

    window.addEventListener('resize', deviceWidth);
    window.addEventListener('load', deviceWidth);
  
    return () => {
      window.removeEventListener('resize', deviceWidth);
      window.removeEventListener('load', deviceWidth);
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className='AppBody'>
      <div className='device-width'>
        <div className='phone-profile-icon'>
          <img src={ProfileIMG} alt="M.Zeeshan Ali" />
        </div>
        <div className={`phone-download-button ${handleContactDetail ? 'd-block' : 'd-none'}`}>
          <a href={ZeeshanCV} download="M.Zeeshan Ali CV">Download CV</a>
        </div>
        <div className={`AppLeftSide radius-10 shadow`}>
          <LeftSideBar Name="Zeeshan Ali"/>
        </div>
        <div className='MidBody'>
          <div className='MainBodyContentSpace'></div>
          <div className='MainBodyContent shadow radius-10'>
            <Routes>
                <Route path='/' element={<About RouteTitle="Zeeshan Ali | About" />} />
                <Route path='/portfolio' element={<Portfolio RouteTitle="Zeeshan Ali | Portfolio" />} />
                <Route path='/resume' element={<Resume RouteTitle="Zeeshan Ali | Resume" />} />
                <Route path='/contact' element={<Contact RouteTitle="Zeeshan Ali | Contact" />} />
            </Routes>
          </div>
          <div className='copyright-section'>
            <p>
              Copyright &copy; All Rights Resrved
            </p>
          </div>
        </div>
        <div className='AppRightSide radius-10 shadow'>
          <RightSideBar />
        </div>
      </div>
    </div>
  )
}

export default App;
