import React from 'react'
import { NavLink } from 'react-router-dom'
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { MdContactEmergency } from "react-icons/md";
import { IoHome } from "react-icons/io5";
import { TbInfoOctagon } from "react-icons/tb";

const RightSideBar = () => {
  return (
    <div className='right-menu'>
      <h3>Direct Links</h3>
      <ul>
        <li>
          <NavLink to="/" activeClassName="active" className="tooltip" data-tooltip='About'>
            <IoHome className='smallDeviceMenu' />
            <span className='largeDeviceMenu'>About</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/portfolio" activeClassName="active" className="tooltip" data-tooltip='Portfolio'>
            <AiOutlineFundProjectionScreen className='smallDeviceMenu' />
            <span className="largeDeviceMenu">Portfolio</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/resume" activeClassName="active" className="tooltip" data-tooltip='Resume'>
            <TbInfoOctagon className='smallDeviceMenu' />
            <span className="largeDeviceMenu">Resume</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/contact" activeClassName="active" className="tooltip" data-tooltip='Contact'>
            <MdContactEmergency className='smallDeviceMenu' />
            <span className="largeDeviceMenu">Contact</span>
          </NavLink>
        </li>
      </ul>
    </div>
  )
}

export default RightSideBar