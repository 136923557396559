import React from 'react'
import { Helmet } from 'react-helmet'
import { RiMailSendFill } from "react-icons/ri";
import GoogleMapPlugin from './GoogleMapPlugin';
import { FaFacebookF, FaGithub, FaLinkedinIn, FaMapLocationDot, FaPhone } from 'react-icons/fa6';

const Contact = (props) => {
  const Open = () => {
    return;
  }

  return (
    <div className='w-100 px-5'>
      <Helmet>
        <title>{props.RouteTitle}</title>
      </Helmet>
      <div className='phone-contact-details'>
        <div className='content-head-container pt-5'>
          <h1>Contact</h1>
          <div className="head-line"></div>
        </div>
        <hr className='left-divider my-5' />
        <div className="left-social-container">
          <a
            href='https://www.facebook.com/cdh.webcraft'
            target='__blank__'
            className="social" 
          >
            <FaFacebookF />
          </a>
          <a
            href='https://www.linkedin.com/in/shani-cdh/'
            target='__blank__'
            className="social"
          >
            <FaLinkedinIn />
          </a>
          <a
            href='https://github.com/shanigtech'
            target='__blank__'
            className="social"
          >
            <FaGithub />
          </a>
        </div>
        <hr className='left-divider' />
        <div className="left-contact">
          <ul>
            <li>
              <div className="contact-icon-div"><RiMailSendFill /></div>
              <p>shanigtech@gmail.com</p>
            </li>
            <li>
              <div className="contact-icon-div"><FaPhone /></div>
              <p>+92 323 594-8057</p>
            </li>
            <li>
              <div className="contact-icon-div"><FaMapLocationDot /></div>
              <p>Gujar Khan<br/>Rawalpindi PB, Pakistan</p>
            </li>
          </ul>
        </div>
      </div>
      <div className="content-head-container pt-5">
        <h1>Get in touch!</h1>
        <div className="head-line"></div>
      </div>
      <div className="contact-container p-2 my-5">
        <form onSubmit={Open} method='get'>
          <div className='flex-row space-between m-0 w-100'>
            <input type="text" name="name" id="name" placeholder='Name' />
            <input type="text" name="contact" id="contact" placeholder='Contact' />
          </div>
            <input type="text" name="subject" id="subject" placeholder='Subject' />
          <textarea name="message" id="message" className='w-100' placeholder='Enter your messege...'></textarea>
          <button type="submit">Send</button>
        </form>
      </div>
      <GoogleMapPlugin />
    </div>
  )
}

export default Contact