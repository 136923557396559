import React from 'react'
import { Helmet } from 'react-helmet'
import { MdDesignServices, MdDeveloperMode } from "react-icons/md";
import { SiAntdesign } from "react-icons/si";
import { FaWordpress } from "react-icons/fa6";

const About = (props) => {
  return (
    <div className='w-100 px-5'>
      <Helmet>
        <title>{props.RouteTitle}</title>
      </Helmet>
      {/* About Section */}
      <div className="content-head-container pt-5">
        <h1>About</h1>
        <div className="head-line"></div>
      </div>
      <p className='about-para px-5'>
        I am a seasoned website developer with the expertise to build any website you need. With a strong background in web development, I excel in creating a variety of websites, including e-commerce platforms, portfolio sites, and corporate webpages.
      </p>
      <p className='about-para px-5'>
        My skill set includes PHP, MySQL, JavaScript, React.js, HTML, and CSS, allowing me to develop complex functionalities and stunning designs. I specialize in crafting custom solutions tailored to meet your specific needs and exceed your expectations.
      </p>
      <p className='about-para px-5'>
        I am dedicated to transforming your ideas into fully functional and visually captivating online presences. My goal is to deliver results that not only elevate your online presence but also drive success for your business. Let me bring your vision to life with precision and creativity.
      </p>
      {/* Service Section */}
      <div className="content-head-container">
        <h1>Services</h1>
        <div className="head-line"></div>
      </div>
      <div className="service-container">
        <div className="service-section">
          <div className="service-title">
            <SiAntdesign className='service-icon' />
            <h2>UI/UX Designing</h2>
          </div>
          <p>
            As a UI/UX designer, I create intuitive and engaging interfaces, ensuring your digital products are both functional and visually appealing.
          </p>
        </div>
        <div className="service-section">
          <div className="service-title">
            <MdDeveloperMode className='service-icon' />
            <h2>Web Development</h2>
          </div>
          <p>
            I specialize in web development using PHP, MySQL, JavaScript, React.js, HTML, and CSS to create high-quality, custom websites that meet your specific needs.
          </p>
        </div>
        <div className="service-section">
          <div className="service-title">
            <FaWordpress className='service-icon' />
            <h2>Worpress Website</h2>
          </div>
          <p>
            WordPress, the world's leading website builder, powers over 70 million sites. Its vast themes, plugins, and active expert community make it the top choice for website creation.
          </p>
        </div>
        <div className="service-section">
          <div className="service-title">
            <MdDesignServices className='service-icon' />
            <h2>Custom Website</h2>
          </div>
          <p>
            With over two years of experience in PHP, MySQL, JavaScript, React.js, HTML, and CSS, I can create custom websites with precision, whether simple or feature-rich, tailored to your specifications.
          </p>
        </div>
      </div>
    </div>
  )
}

export default About