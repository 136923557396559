import React from 'react'

const GoogleMapPlugin = () => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53381.052937344386!2d73.26775749266704!3d33.25822360701226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x392005bea6c1b8cf%3A0xc1ef0608b44b1eab!2sGujar%20Khan%2C%20Rawalpindi%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1711865703956!5m2!1sen!2s"
      width="95%"
      height="350px"
      style={{ border: 'none', borderRadius: '5px', marginLeft: '2.5%' }}
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
      title='Google Mapn Plugin'
    ></iframe>
  )
}

export default GoogleMapPlugin