import { React } from 'react'
import { Link } from 'react-router-dom'
import { RiMailSendFill } from "react-icons/ri";
import { FaMapLocationDot, FaPhone } from "react-icons/fa6";
import { FaFacebookF, FaLinkedinIn, FaGithub } from "react-icons/fa";

import ProfileIMG from '../media/img/zeeshan.png' 
import ZeeshanCV from '../media/cv/M.Zeeshan Ali Resume.pdf' 

const LeftSideBar = (props) => {
  return (
    <div className='w-100'>
      <div className="banner-image">
        <div className="image-container">
          <div className='image-shadow-animation absolute'></div>
          <div className="image-outer shadow">
            <img src={ProfileIMG} alt="M. Zeeshan Ali" className='w-100' />
          </div>
        </div>
      </div>
      <h2 className='profile-name align-center'>{props.Name}</h2>
      <div className='left-category'>
        <p>UI/UX Designer</p>
      </div>
      <hr className='left-divider my-5' />
      <div className="left-social-container">
        <a
          href='https://www.facebook.com/cdh.webcraft'
          target='__blank__'
          className="social" >
          <FaFacebookF />
        </a>
        <a
          href='https://www.linkedin.com/in/shani-cdh/'
          target='__blank__'
          className="social">
          <FaLinkedinIn />
        </a>
        <a
          href='https://github.com/shanigtech'
          target='__blank__'
          className="social">
          <FaGithub />
        </a>
      </div>
      <hr className='left-divider' />
      <div className="left-contact">
        <ul>
          <li>
            <div className="contact-icon-div"><RiMailSendFill /></div>
            <p>shanigtech@gmail.com</p>
          </li>
          <li>
            <div className="contact-icon-div"><FaPhone /></div>
            <p>+92 323 594-8057</p>
          </li>
          <li>
            <div className="contact-icon-div"><FaMapLocationDot /></div>
            <p>Gujar Khan<br/>Rawalpindi PB, Pakistan</p>
          </li>
        </ul>
      </div>
      <div className="left-cv-div">
        <a href={ZeeshanCV} download="M.Zeeshan Ali CV">Download CV</a>
      </div>
    </div>
  )
}

export default LeftSideBar