import React from 'react'
import { Helmet } from 'react-helmet'

const Resume = (props) => {
  return (
    <div className='w-100 px-5'>
      <Helmet>
        <title>{props.RouteTitle}</title>
      </Helmet>
      <div className="content-head-container pt-5">
        <h1>Resume</h1>
        <div className="head-line"></div>
      </div>
      <div className='p-2 my-5'>
        <p className='m-0 black-para px-2'>
          As a skilled full stack website developer specializing in the MERN stack, I bring extensive expertise in designing and implementing a wide range of websites, from simple portfolios to complex e-commerce platforms. Leveraging MongoDB, Express.js, React, and Node.js, I ensure robust, high-performance solutions that cater to unique business needs and deliver exceptional user experiences.
        </p>
        <p className=' black-para px-2'>
          With a focus on crafting custom solutions with complex functionalities and stunning designs, I transform ideas into fully functional and visually captivating online presences that drive success. My technical skill set includes proficiency in HTML, CSS, JavaScript, React, Redux, Node.js, Express.js, and MongoDB, along with experience in tools like Git, Docker, Jenkins, and Webpack.
        </p>
        <p className='black-para px-2'>
          I am passionate about maintaining code quality through best practices and code reviews, and I thrive in collaborative environments where I can mentor junior developers and provide technical guidance. My professional journey includes developing high-performance web applications, creating responsive and user-friendly interfaces, and integrating third-party services to enhance functionality.
        </p>
        <p className='black-para px-2'>
          Whether working on advanced search and filtering functionalities for e-commerce platforms or designing personal portfolio websites, I strive to deliver optimal performance and scalability. My educational background in computer science, combined with certifications in MERN stack and full stack web development, further solidifies my ability to deliver high-quality solutions tailored to meet diverse business objectives.
        </p>
        <h5 className='f-17 strong-head'>Passion and Goals</h5>
        <p className='black-para px-2'>
          I am passionate about developing high-quality websites that not only meet but exceed client expectations. I take pride in creating user-friendly interfaces and ensuring optimal performance and scalability. My goal is to continue refining my skills and expanding my expertise, staying at the forefront of web development technologies to deliver innovative solutions.
        </p>
        <h5 className='f-17 strong-head'>Work Experience</h5>
        <p className='black-para px-2'>
          <b>East & West IT Company</b> (Advertiser) <sup>(2020 - 2022)</sup>
          <br />
          I worked as an advertiser at East & West IT Company, where I was responsible for uploading different kinds of businesses on over 600 websites. This role honed my attention to detail and ability to manage large-scale online content.
        </p>
        <p className='black-para px-2'>
          <b>Local Market</b> (Self-Employed) <sup>(2022 - Present)</sup>
          <br />
          Currently, I am working as a self-employed front-end website developer in the local market, where I have been providing my services for over 2 years. This experience has allowed me to apply my skills directly to meet client needs and stay updated with the latest industry trends.
        </p>
      </div>
      <div className="content-head-container">
        <h1>Certification</h1>
        <div className="head-line"></div>
      </div>
      <div className="cert-container p-2 my-5">
        <div className="certificate-box">
          <h2>SSC <span>2020</span></h2>
          <div className="bar"></div>
          <p className='black-para'>
            I completed my secondary education at Suffah Secondary School System in 2020, graduating with 656 marks. I consistently demonstrated academic excellence and received numerous awards for outstanding performance in class and studies.
          </p>
        </div>
        <div className="certificate-box">
          <h2>I.Com <span>2024</span></h2>
          <div className="bar"></div>
          <p className='black-para'>
            I graduated from MentorUs College in 2024 with a degree in I.Com, earning 800 marks. My time there was marked by dedication and hard work, reflecting my commitment to excellence.
          </p>
        </div>
        <div className="certificate-box">
          <h2>HTML/CSS/Js <span>2023</span></h2>
          <div className="bar"></div>
          <p className='black-para'>
            By 2023, I mastered HTML, CSS, and JavaScript, enabling me to create responsive and interactive web pages. These skills set the stage for my advanced work in full stack development.
          </p>
        </div>
        <div className="certificate-box">
          <h2>MERN Stack <span>2024</span></h2>
          <div className="bar"></div>
          <p className='black-para'>
            In 2024, I specialized in the MERN stack (MongoDB, Express.js, React, Node.js), enabling me to design and implement a wide range of websites. My expertise ensures robust, high-performance solutions tailored to unique business needs.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Resume