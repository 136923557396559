import { React, useState } from 'react';
import { Helmet } from 'react-helmet'
import { SiGooglelens } from "react-icons/si";
import CDHWebCraft from '../media/portfolio/CDH WebCraft.png'
import CDHBlog from '../media/portfolio/CDH Blogs.png'
import JasperNaina from '../media/portfolio/Jasper Naina.png'
import CreativeSolutions from '../media/portfolio/Creative Solutions (1).png'
import StudioVIP from '../media/portfolio/Studio VIp.png'
import { FaExternalLinkAlt } from 'react-icons/fa';

const Portfolio = (props) => {
  
  const [selectedTab, setSelectedTab] = useState(0);
  const [fullScreenImage, setFullScreenImage] = useState(null);

  const handleTabChange = (index) => {
    setSelectedTab(index);
  };

  const handleImageClick = (imageSrc) => {
    setFullScreenImage(imageSrc);
  };
  
  return (
    <div className='w-100 px-5'>
      <Helmet>
        <title>{props.RouteTitle}</title>
      </Helmet>
      <div className="content-head-container pt-5">
        <h1>Portfolio</h1>
        <div className="head-line"></div>
      </div>
      <div className="portfolio-container w-100 p-3">
        <div className="tab-wrap">
          <ul>
            <li>
              <input
                type="radio"
                id="cdh-services-tab1"
                name="cdh-services-tab"
                checked={selectedTab === 0}
                onChange={() => handleTabChange(0)}
              />
              <label htmlFor="cdh-services-tab1">Portfolio</label>
              <div className="tab-content" style={{ display: selectedTab === 0 ? 'flex' : 'none' }}>
                <div className="project-container">
                  <img
                    src={CDHWebCraft}
                    alt="CDH WebCraft"
                    onClick={() => handleImageClick(CDHWebCraft)}
                  />
                  <div className="hover-detail">
                    <h3>CDH WebCraft</h3>
                    <button onClick={() => handleImageClick(CDHWebCraft)}><FaExternalLinkAlt /></button>
                  </div>
                </div>
                <div className="project-container">
                  <img
                    src={JasperNaina}
                    alt="Jasper Naina"
                    onClick={() => handleImageClick(JasperNaina)}
                  />
                  <div className="hover-detail">
                    <h3>Jasper Naina</h3>
                    <button onClick={() => handleImageClick(JasperNaina)}><FaExternalLinkAlt /></button>
                  </div>
                </div>
                <div className="project-container">
                  <img
                    src={CreativeSolutions}
                    alt="Creative Solutions"
                    onClick={() => handleImageClick(CreativeSolutions)}
                  />
                  <div className="hover-detail">
                    <h3>Creative Solutions</h3>
                    <button onClick={() => handleImageClick(CreativeSolutions)}><FaExternalLinkAlt /></button>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <input
                type="radio"
                id="cdh-services-tab2"
                name="cdh-services-tab"
                checked={selectedTab === 1}
                onChange={() => handleTabChange(1)}
              />
              <label htmlFor="cdh-services-tab2">Business</label>
              <div className="tab-content" style={{ display: selectedTab === 1 ? 'flex' : 'none' }}>
                <div className="project-container">
                  <img
                    src={StudioVIP}
                    alt="Studio VIP"
                    onClick={() => handleImageClick(StudioVIP)}
                  />
                  <div className="hover-detail">
                    <h3>Studio VIP</h3>
                    <button onClick={() => handleImageClick(StudioVIP)}><FaExternalLinkAlt /></button>
                  </div>
                </div>
                <div className="project-container">
                  <img
                    src={CDHBlog}
                    alt="CDH Blog"
                    onClick={() => handleImageClick(CDHBlog)}
                  />
                  <div className="hover-detail">
                    <h3>CDH Blog</h3>
                    <button onClick={() => handleImageClick(CDHBlog)}><FaExternalLinkAlt /></button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <hr />
        </div>
      </div>
      
      {fullScreenImage && (
        <div className="fullscreen-image-container">
          <div className="fullscreen-image-div">
            <img src={fullScreenImage} alt="Fullscreen" />
          </div>
          <button className='flex-center' onClick={() => setFullScreenImage(null)}>&times;</button>
        </div>
      )}
    </div>
  )
}

export default Portfolio